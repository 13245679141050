class homePage extends Component {

    static  name() {
        return "homePage";
    }

    static componentName() {
        return "homePage";
    }

    getTemplate() {
        return `<div :ref="this.getId">
              <headerSectionComponent></headerSectionComponent>
              <div :class="classContainer" class="px-0">
                  <div class="section-main" v-if="$store.state.itemsFullLoader">
                      <centralContainerComponent></centralContainerComponent>
                  </div>
              </div>
              <footerSectionComponent></footerSectionComponent>
          </div>`;
    }
}

homePage.registerComponent();

